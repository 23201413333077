import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { CosBootstrapState } from '@cosmos/core';
import { loadPolyfills } from '@cosmos/polyfills';

import { AppComponent } from './app/app.component';
import { appConfig } from './config/app.config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

loadPolyfills();

// eslint-disable-next-line no-restricted-globals
window.cosCanBootstrapPromise
  .then(() => bootstrapApplication(AppComponent, appConfig))
  .then(({ injector }) => injector.get(CosBootstrapState).bootstrap());
