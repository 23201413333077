import {
  makeEnvironmentProviders,
  type EnvironmentProviders,
} from '@angular/core';
import { provideStates } from '@ngxs/store';

import { ProductInventoryState } from './states';

export function provideDataAccessProductInventory(): EnvironmentProviders {
  return makeEnvironmentProviders([provideStates([ProductInventoryState])]);
}
