import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import type {
  AuthServiceConfig,
  LoginRequest,
  SessionBase,
} from '@asi/auth/types-auth';
import { ConfigService } from '@cosmos/config';

import { AuthTokenService } from './auth-token.service';

export abstract class BaseAsiAuthService<
  TRequest = unknown,
  TResponse = unknown,
> {
  abstract getToken(): Observable<string>;

  abstract refreshToken(): Observable<string | null>;

  abstract login(credentials: TRequest): Observable<TResponse>;

  // eslint-disable-next-line unused-imports/no-unused-vars
  loginWithToken(token: string): Observable<TResponse> {
    throw new Error('Not implemented');
  }

  logout() {
    // call service?
    return of(true);
  }

  /**
   * It returns a tokenized URI where we should connect via websocket
   */
  websocketURI$(): Observable<string> {
    throw new Error('Not implemented');
  }
}

@Injectable({
  providedIn: 'root',
})
export class AsiAuthService extends BaseAsiAuthService<
  LoginRequest,
  SessionBase
> {
  private _http: HttpClient;
  private _config: AuthServiceConfig;

  /**
   * Constructor
   *
   * @param {HttpBackend} handler
   */
  constructor(
    private readonly _configService: ConfigService,
    private _tokenService: AuthTokenService,
    handler: HttpBackend
  ) {
    super();
    this._config = this._configService.get('auth');
    this._http = new HttpClient(handler);
  }

  getToken() {
    return of(this._tokenService.token);
  }

  refreshToken(): Observable<string | null> {
    return of(null);
  }

  login(credentials: LoginRequest, grant_type = 'password') {
    const options = {
      headers: new HttpHeaders().set(
        'Authorization',
        this._config.AuthorizationHeader!
      ),
    };

    const body = {
      grant_type,
      ...credentials,
      scope: 'AsiNumberOptional',
    };

    return this._http.post<SessionBase>(
      `${this._config.Url}${this._config.TokenPath}`,
      body,
      options
    );
  }
}
