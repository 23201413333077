import { Languages } from '@cosmos/util-translations';

// Day of month and years are expressed differently in angular pipe and dayjs
//
// Angular:
// - Numeric: minimum digits: "d". Example: 1
// - Numeric: 2 digits + zero padded: "dd". Example: 01
//
// Dayjs:
// - Numeric: minimum digits: "D". Example: 1
// - Numeric: 2 digits + zero padded: "DD". Example: 01

export function getLongDateFormat(
  currentLang: Languages,
  isAngularDateFormat: boolean
): string {
  switch (currentLang) {
    case Languages.EnCa:
    case Languages.FrCa:
    case Languages.Es: {
      if (!isAngularDateFormat) {
        return 'YYYY-MM-DD';
      }
      return 'yyyy-MM-dd';
    }
    case Languages.EnUs:
    default: {
      if (!isAngularDateFormat) {
        return 'MMMM D, Y';
      }
      return 'MMMM d, y';
    }
  }
}

export function getShortDateFormat(
  currentLang: Languages,
  isAngularDateFormat: boolean
): string {
  switch (currentLang) {
    case Languages.EnCa:
    case Languages.FrCa: {
      if (!isAngularDateFormat) {
        return 'YYYY-MM-DD';
      }
      return 'yyyy-MM-dd';
    }
    case Languages.EnUs:
    default: {
      if (!isAngularDateFormat) {
        return 'MM/DD/YYYY';
      }
      return 'MM/dd/yyyy';
    }
  }
}
