import { InjectionToken, inject } from '@angular/core';
import { StateToken } from '@ngxs/store';
import { ɵMETA_OPTIONS_KEY as _META_OPTIONS_KEY } from '@ngxs/store/internals';

/**
 * The following key is used to store the entire serialized
 * state when no specific state is provided.
 */
const ɵDEFAULT_STATE_KEY = '@@STATE';
const ɵUSER_OPTIONS = new InjectionToken(typeof ngDevMode !== 'undefined' && ngDevMode ? 'USER_OPTIONS' : '');
// Determines whether all states in the NGXS registry should be persisted or not.
const ɵALL_STATES_PERSISTED = new InjectionToken(typeof ngDevMode !== 'undefined' && ngDevMode ? 'ALL_STATES_PERSISTED' : '', {
  providedIn: 'root',
  factory: () => inject(ɵUSER_OPTIONS).keys === '*'
});
const ɵNGXS_STORAGE_PLUGIN_OPTIONS = new InjectionToken(typeof ngDevMode !== 'undefined' && ngDevMode ? 'NGXS_STORAGE_PLUGIN_OPTIONS' : '');
const STORAGE_ENGINE = new InjectionToken(typeof ngDevMode !== 'undefined' && ngDevMode ? 'STORAGE_ENGINE' : '');

/** Determines whether the provided key has the following structure. */
function ɵisKeyWithExplicitEngine(key) {
  return key != null && !!key.engine;
}
function ɵextractStringKey(storageKey) {
  // Extract the actual key out of the `{ key, engine }` structure.
  if (ɵisKeyWithExplicitEngine(storageKey)) {
    storageKey = storageKey.key;
  }
  // Given the `storageKey` is a class, for instance, `AuthState`.
  // We should retrieve its metadata and the `name` property.
  // The `name` property might be a string (state name) or a state token.
  if (storageKey.hasOwnProperty(_META_OPTIONS_KEY)) {
    storageKey = storageKey[_META_OPTIONS_KEY].name;
  }
  return storageKey instanceof StateToken ? storageKey.getName() : storageKey;
}

/**
 * Generated bundle index. Do not edit.
 */

export { STORAGE_ENGINE, ɵALL_STATES_PERSISTED, ɵDEFAULT_STATE_KEY, ɵNGXS_STORAGE_PLUGIN_OPTIONS, ɵUSER_OPTIONS, ɵextractStringKey, ɵisKeyWithExplicitEngine };
