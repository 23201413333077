import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  fromEvent,
  race,
  take,
} from 'rxjs';

import { CosTheme } from '@cosmos/types-common';

import { COS_THEMES_CONFIG } from './themes.types';

@Injectable()
export class ThemesService {
  readonly config = inject(COS_THEMES_CONFIG);
  private readonly _document = inject(DOCUMENT);
  private _loaded = new Set<CosTheme>();
  private _current$ = new BehaviorSubject(this.config.defaultTheme);
  readonly current$ = this._current$.pipe(distinctUntilChanged());

  constructor() {
    if (this.config.defaultTheme) this._loaded.add(this.config.defaultTheme);
  }

  async init() {
    return this.apply(this.config.defaultTheme);
  }

  async apply(theme: CosTheme) {
    try {
      if (theme && !this._loaded.has(theme)) await this._load(theme);

      const htmlEl = this._document.documentElement;

      /** Remove all other themes class names */
      Object.values(CosTheme).forEach((t) =>
        htmlEl.classList.remove(`cos-theme-${t}`)
      );

      if (theme) {
        /** Add selected theme class */
        htmlEl.classList.add(`cos-theme-${theme}`);
      }

      this._current$.next(theme);
    } catch (error) {
      console.error(error);
    }
  }

  async applyDefaultTheme() {
    await this.apply(this.config.defaultTheme);
  }

  private async _load(theme: CosTheme) {
    return new Promise<void>((resolve) => {
      const styleUrl = `/${theme}-theme.css`;
      const linkEl = this._document.createElement('link');
      linkEl.setAttribute('rel', 'stylesheet');
      linkEl.setAttribute('href', styleUrl);

      race(fromEvent(linkEl, 'error'), fromEvent(linkEl, 'load'))
        .pipe(take(1))
        .subscribe((event) => {
          if (event.type === 'load') {
            this._loaded.add(theme);
            resolve();
          }
        });

      this._document.head.appendChild(linkEl);
    });
  }
}
