import { AuthServiceConfig } from '@asi/auth/types-auth';
import { provideConfig } from '@cosmos/config';
import { CosmosUtilTranslationsModule } from '@cosmos/util-translations';

import type { EnvironmentConfig } from '../app/core/types/environment-config';

import { configOverrides, importOverrides } from './dev-overrides';

// dev-asicentral.com
const ASICENTRAL_DOMAIN =
  configOverrides.asiCentralDomain ||
  process.env['ASICENTRAL_DOMAIN'] ||
  'dev-asicentral.com';

const production = false;

export const environment: EnvironmentConfig = {
  production,

  imports: [
    CosmosUtilTranslationsModule.forRoot({ production, appName: 'ad-content' }),
    ...importOverrides,
  ],

  providers: [
    provideConfig({
      auth: new AuthServiceConfig(),
      smartlinkAuthHeader:
        'AsiMemberAuth client_id=500000311&client_secret=1779a8c6cc63161aa8bbdc07cc835b09',
      smartlinkApiUrl: `https://api.${ASICENTRAL_DOMAIN}/v1`,
      pamApiUrl: `https://pam.${ASICENTRAL_DOMAIN}/esp/v1`,
    }),
  ],
};
