import type { HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { JwtInterceptor } from '@asi/auth/data-access-auth';
import { ConfigService } from '@cosmos/config';

@Injectable({
  providedIn: 'root',
})
export class SmartlinkDataAccessAuthInterceptor
  extends JwtInterceptor
  implements HttpInterceptor
{
  private readonly _configService = inject(ConfigService);

  private _baseUrl: string = this._configService
    .get('smartlinkApiUrl')
    .replace('/v1', '');

  override canIntercept = (req: HttpRequest<any>): boolean =>
    req.url.startsWith(this._baseUrl);

  override interceptToken(req: HttpRequest<any>, token: string) {
    if (
      !req.url.endsWith('.json') &&
      req.url.startsWith(`${this._baseUrl}/v`)
    ) {
      req = req.clone({ url: `${req.url}.json` });
    }

    const authHeader = this.authHeader(token);

    if (authHeader) {
      req = req.clone({
        setHeaders: {
          Authorization: authHeader,
        },
        withCredentials: false,
      });
    }

    return req;
  }

  authHeader(token: string) {
    return token?.split(' ')?.length > 1 ? token : token && `Bearer ${token}`;
  }
}
