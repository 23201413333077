import { inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { IDLE$ } from '@cosmos/tick-scheduler';

@Injectable({ providedIn: 'root' })
export class CosBootstrapState extends ReplaySubject<boolean> {
  private readonly _idle$ = inject<Observable<void>>(IDLE$);
  appHasBeenBootstrapped$ = this.asObservable();

  constructor() {
    super(1);
  }

  bootstrap(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._idle$.subscribe(() => {
      this.next(true);
      this.complete();
    });
  }
}
