import { InjectionToken } from '@angular/core';

import type { CosTheme } from '@cosmos/types-common';

export interface CosThemesConfig {
  appId: string;
  defaultTheme: CosTheme;
  lsKey: string;
}

export const COS_THEMES_CONFIG = new InjectionToken<CosThemesConfig>(
  ngDevMode ? 'COS_THEMES_CONFIG' : ''
);
