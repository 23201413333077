import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SmartlinkDataAccessAuthInterceptor } from '@smartlink/data-access-auth';

export const provideInterceptors = () => {
  return [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SmartlinkDataAccessAuthInterceptor,
      multi: true,
    },
  ];
};
