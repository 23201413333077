/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectionToken } from '@angular/core';

export type CosDateFormats = {
  parse: {
    dateInput: any;
  };
  display: {
    dateInput: any;
    monthLabel?: any;
    monthYearLabel: any;
    dateA11yLabel: any;
    monthYearA11yLabel: any;
  };
};

export const COS_DATE_FORMATS = new InjectionToken<CosDateFormats>(
  ngDevMode ? 'cos-date-formats' : ''
);
