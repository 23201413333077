import type { HttpRequest } from '@angular/common/http';

// eslint-disable-next-line @nx/enforce-module-boundaries
import type { AuthServiceConfigOptions } from '@cosmos/config/item-models';

export class AuthServiceConfig implements AuthServiceConfigOptions {
  Url = 'https://authentication.asicentral.com';
  TokenPath? = '/oauth2/token';
  StorageStrategy: 'cookie' | 'localStorage' = 'cookie';
  AuthorizationHeader?: string;
  canIntercept?: (request: HttpRequest<any>) => boolean = () => true;

  constructor(options?: Partial<AuthServiceConfig>) {
    Object.assign(this, options);
  }
}
